'use client'

import { useState, useCallback, useEffect } from 'react'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import dayjs from 'dayjs'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Typography, Paper, Button, Badge } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton'
import { PickersDay } from '@mui/x-date-pickers/PickersDay'

// Types
import type { PickersDayProps } from '@mui/x-date-pickers'
import type { Dayjs } from 'dayjs'
import type { EventItem, EventsProps } from '@/types/components/molecules/events'
import type { APIArticleReadData } from '@/types/api/article/read'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Utils
import TransText from '@/utils/trans-text'

// Fonts
import RobotoFont from '@/styles/fonts/roboto'

// Componenets
const TextAtom = dynamic(() => import('@/components/atoms/text'))

function RenderDay(props: PickersDayProps<Dayjs> & { highlightedDays?: string[] }) {
	const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props
	const dateS = dayjs(day).format('MMM DD, YYYY').toString()
	const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(dateS) >= 0

	return (
		<Badge key={props.day.toString()} overlap="circular" badgeContent={isSelected ? '🗓' : undefined}>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	)
}

const EventsMolecule = (props: EventsProps) => {
	// Props
	const { id, title } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [show, setShow] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [items, setItems] = useState<EventItem[]>([])
	const [highlightedDays, setHighlightedDays] = useState<string[]>([])
	const [selectedDate, setSelectedDate] = useState<string>(dayjs(new Date().toDateString()).format('MMM DD, YYYY').toString())
	let find = 0

	// Callbacks
	const readItems = useCallback(async () => {
		setLoading(true)
		// Result
		const response = await fetch(`/api/article/read?search=3&type=category&limit=10&skip=0`)
		const result: APIArticleReadData = await response.json()
		setLoading(false)

		if (result.status && result.data && result.data.items) {
			const findHighlightedDays: string[] = []

			// Sort
			let newItems: EventItem[] = result.data.items
				.filter(item => item.status)
				.map(item => {
					// Find
					const { text, components } = TransText(item.description || '')
					let findDate = item.createdDate.toString()

					if (components.length)
						components.map(component => {
							if (component.type === 'date')
								findDate = component.description
									.replace('<p className="editor-paragraph"><span >', '')
									.replace('<p className="editor-paragraph" ><span >', '')
									.replace('</span></p>', '')
						})

					findHighlightedDays.push(dayjs(findDate).format('MMM DD, YYYY').toString())

					return {
						title: item.title.toString(),
						date: findDate,
						description: text
					}
				})

			// Sort events
			newItems = newItems.length
				? newItems.sort((a: EventItem, b: EventItem) => {
						const da = new Date(a.date ?? '')
						const db = new Date(b.date ?? '')

						return da.getTime() - db.getTime()
					})
				: []

			setItems(newItems)
			setHighlightedDays(findHighlightedDays)
		}
	}, [])

	useEffect(() => {
		if (loading) readItems()
	}, [loading, readItems])

	return (
		<Grid
			id={id}
			position="relative"
			overflow="hidden"
			sx={{
				backgroundImage: `linear-gradient(180deg, ${alpha('#f1f6fd', 0.0)} 1.63%, #F1F6FD 20.5%, #F1F6FD 58.57%, #C8D7EF 100%)`,
				backgroundColor: theme.palette.common.white
			}}
		>
			<Grid position="absolute" top={0} left={0} sx={{ opacity: 0.5, zIndex: 1 }}>
				<Image src="/patterns/01.png" width={687} height={614} alt="pattern" />
			</Grid>

			<Grid position="absolute" right={0} bottom={0} sx={{ opacity: 0.4, zIndex: 1 }}>
				<Image src="/patterns/02.png" width={694} height={659} alt="pattern" />
			</Grid>

			<Container maxWidth="md" sx={{ py: { xs: 4, md: 16 }, position: 'relative' }}>
				<Grid position="relative" sx={{ zIndex: 2 }}>
					{title && (
						<Typography variant="h3" component="h3" fontSize={{ xs: 24, md: 48 }} fontWeight={700} color="#112B3C">
							{t(title)}
						</Typography>
					)}

					<Grid container mt={{ xs: 2, md: 4 }} spacing={{ xs: 4, md: 8 }}>
						<Grid item xs={12} sm={6}>
							<Paper>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateCalendar
										renderLoading={() => <DayCalendarSkeleton />}
										slots={{
											day: RenderDay
										}}
										slotProps={{
											day: { highlightedDays } as any
										}}
										onChange={(value: Date | null) => {
											if (value) {
												const newSelectedDate = dayjs(value.toString()).format('MMM DD, YYYY').toString()
												setSelectedDate(newSelectedDate)

												for (const index in items) {
													const item = items[index]
													const showItem = dayjs(item.date).diff(dayjs(newSelectedDate))

													if (showItem === 0) {
														setShow(Number(index))
														break
													}
												}
											}
										}}
										sx={{
											'& .MuiDayCalendar-weekDayLabel': {
												fontSize: '1.125em',
												fontWeight: 500,
												color: theme.palette.mode == 'dark' ? '#ddd' : '#1f1f1f'
											},
											'& button.MuiPickersDay-root': {
												fontFamily: RobotoFont.style.fontFamily,
												fontSize: '1.125em',
												fontWeight: 500,
												color: theme.palette.mode == 'dark' ? theme.palette.common.white : '#1f1f1f'
											},
											'& button.MuiPickersDay-today': {
												color: theme.palette.common.white,
												backgroundColor: theme.palette.secondary.main,
												border: 'inherit',
												borderRadius: 0.4
											},
											'& button.Mui-selected': {
												border: 'inherit',
												borderRadius: 0.4
											},
											'& .MuiPickersCalendarHeader-root': {
												justifyContent: 'center',
												p: 'inherit',
												pb: 1.25,
												position: 'relative',
												'& div.MuiPickersArrowSwitcher-spacer': { display: 'none' }
											},
											'& .MuiPickersCalendarHeader-labelContainer': {
												m: 'inherit',
												pl: 1,
												color: theme.palette.mode == 'dark' ? theme.palette.common.white : '#141414',
												textAlign: 'center',
												fontFamily: 'Roboto',
												fontSize: '1em',
												fontWeight: 700,
												letterSpacing: -0.24,
												lineHeight: '2.25rem',
												borderRadius: 0.375,
												border: '2px solid #2F80ED',
												boxShadow: `0px 1px 1px 0px ${alpha('#000e33', 0.05)}`,
												'& .MuiPickersCalendarHeader-label': { transitionDuration: '0s' }
											},
											'& .MuiPickersArrowSwitcher-button': {
												position: 'absolute',
												top: -5,
												color: theme.palette.mode == 'dark' ? '#ddd' : theme.palette.common.black,
												boxShadow: `0px 1px 1px 0px ${alpha('#000e33', 0.05)}`,
												'&:first-child': {
													left: 0
												},
												'&:last-child': {
													right: 0
												},
												'&:hover': {
													color: theme.palette.mode == 'dark' ? theme.palette.common.white : '#f2f2f2',
													backgroundColor: alpha(theme.palette.secondary.main, 0.4)
												}
											}
										}}
									/>
								</LocalizationProvider>
							</Paper>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Grid container width="100%" height={320}>
								{items.map((item, index) => {
									return (
										<Grid key={index} item sx={{ display: index === show ? 'block' : 'none' }}>
											{item.date && (
												<Grid
													item
													xs={12}
													mb={{ xs: 2, md: 4 }}
													py={1.5}
													px={3}
													sx={{
														fontWeight: 700,
														letterSpacing: 3.2,
														color: '#014178',
														backgroundColor: '#C8D7EF',
														borderRadius: 2
													}}
												>
													{dayjs(item.date).format('MMM DD, YYYY').toString()}
												</Grid>
											)}

											{item.title && (
												<Grid
													item
													xs={12}
													mb={{ xs: 1.5, md: 3 }}
													sx={{
														fontSize: '1.5em',
														fontWeight: 700,
														color: theme.palette.common.black
													}}
												>
													{item.title}
												</Grid>
											)}

											{item.description && (
												<Grid
													item
													xs={12}
													sx={{
														fontWeight: 400,
														lineHeight: '1.25em',
														color: '#7D7D84'
													}}
												>
													<TextAtom>{item.description}</TextAtom>
												</Grid>
											)}
										</Grid>
									)
								})}
							</Grid>

							<Grid container direction="row" justifyContent="flex-end" alignItems="center" mt={2} spacing={1}>
								{items.map((item, index) => {
									// const showItem = dayjs(item.date).diff(dayjs(selectedDate).subtract(1, 'day'))

									return (
										<Grid key={index} item>
											<Button
												onClick={() => {
													setShow(index)
												}}
												sx={{
													minWidth: '2.4rem',
													p: 0,
													fontSize: '1em',
													fontWeight: 700,
													lineHeight: '2.4rem',
													letterSpacing: 3.2,
													color: index === show ? '#ed1651' : '#7d7d84'
												}}
											>
												{(index + 1 < 10 ? '0' : '') + (index + 1)}
											</Button>
										</Grid>
									)
								})}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default EventsMolecule
