'use client'

import { useState, useCallback, useEffect } from 'react'
import Link from 'next/link'
import dayjs from 'dayjs'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Typography, IconButton, CardMedia, CardContent, Card, CardActions, Button } from '@mui/material'
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon, ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material'

// Types
import type { VideoItem, VideosProps } from '@/types/components/molecules/videos'
import type { APIArticleReadData } from '@/types/api/article/read'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Utils
import TransText from '@/utils/trans-text'

// Fonts
import RobotoFont from '@/styles/fonts/roboto'
import BarlowFont from '@/styles/fonts/barlow'

const VideosMolecule = (props: VideosProps) => {
	// Props
	const { id } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [show, setShow] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [items, setItems] = useState<VideoItem[]>([])

	// Callbacks
	const readItems = useCallback(async () => {
		setLoading(true)
		// Result
		const response = await fetch(`/api/article/read?search=5&type=category&limit=10&skip=0`)
		const result: APIArticleReadData = await response.json()
		setLoading(false)

		if (result.status && result.data && result.data.items)
			setItems(
				result.data.items.map(item => {
					// Find
					const { text, components } = TransText(item.description || '')
					let findDate = item.createdDate.toString()

					if (components.length)
						components.map(component => {
							if (component.type === 'date') findDate = component.description.replace('<p className="editor-paragraph"><span >', '').replace('</span></p>', '')
						})

					const linkResult = / href="([^"]+)"/g.exec(text)

					return {
						title: item.title.toString(),
						date: findDate,
						image: `/api/media/download/${item.image}`,
						link: linkResult && linkResult?.length > 1 ? linkResult[1] : '#'
					}
				})
			)
	}, [])

	useEffect(() => {
		if (loading) readItems()
	}, [loading, readItems])

	return (
		<Grid id={id} overflow="hidden">
			<Container sx={{ py: { xs: 4, md: 16 }, position: 'relative' }}>
				<Grid position="relative" sx={{ zIndex: 2 }}>
					<Grid container justifyContent="space-between" alignItems="center" spacing={{ sm: 4 }} mb={{ md: 2 }}>
						<Grid item xs={12} md={6} mb={2}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography
										variant="h3"
										component="h3"
										fontSize={{ xs: 24, md: 48 }}
										fontWeight={700}
										color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
									>
										{t('common:title.videos')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={6} mb={2}>
							<Grid container justifyContent="end" spacing={1}>
								<Grid item>
									<IconButton
										size="large"
										onClick={() => {
											setShow((show - 1 + items.length) % items.length)
										}}
										sx={{
											color: theme.palette.secondary.main,
											backgroundColor: alpha(theme.palette.secondary.main, 0.1),
											'&:hover': {
												color: '#f2f2f2',
												backgroundColor: alpha(theme.palette.secondary.main, 0.4)
											}
										}}
									>
										<ArrowBackIosNewIcon />
									</IconButton>
								</Grid>

								<Grid item>
									<IconButton
										size="large"
										onClick={() => {
											setShow((show + 1) % items.length)
										}}
										sx={{
											color: theme.palette.secondary.main,
											backgroundColor: alpha(theme.palette.secondary.main, 0.1),
											'&:hover': {
												color: '#f2f2f2',
												backgroundColor: alpha(theme.palette.secondary.main, 0.4)
											}
										}}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container flexWrap="nowrap" spacing={2} width="fit-content">
						{items.map((item, index) => {
							const findItem = items[(index + show) % items.length]

							return (
								<Grid key={index} item width={{ xs: 'calc(100vw - 1em)', sm: '50vw', md: '33.333333vw', lg: '25vw', xl: '20vw', position: 'relative' }}>
									<Link href={findItem.link} target="_blank">
										<Card
											sx={{
												borderRadius: 1,
												boxShadow: `0px 6px 20px 0px ${alpha(theme.palette.grey[900], 0.1)}`,
												'& picture': {
													display: 'block'
												},
												'& img': {
													width: '100%',
													height: 'auto',
													maxHeight: { xs: '50vh', md: '60vh' },
													objectFit: 'cover',
													objectPosition: 'center'
												}
											}}
										>
											{findItem.image && (
												<picture>
													<img src={findItem.image} alt={findItem.title} />
												</picture>
											)}

											<CardContent
												sx={{
													width: 'calc(100% - 16px)',
													position: 'absolute',
													pb: 5,
													bottom: 0,
													left: 16,
													backgroundImage: `linear-gradient(0deg, ${alpha(theme.palette.common.black, 1)} 0%, ${alpha(theme.palette.common.black, 0)} 100%)`,
													borderBottomRightRadius: 16,
													borderBottomLeftRadius: 16,
													zIndex: 1
												}}
											>
												{findItem.title && (
													<Typography fontFamily={RobotoFont.style.fontFamily} fontSize={20} fontWeight={700} color={theme.palette.common.white}>
														{findItem.title}
													</Typography>
												)}
											</CardContent>

											<CardActions
												sx={{ justifyContent: 'space-between', width: 'calc(100% - 16px)', position: 'absolute', bottom: 8, left: 16, px: 2, zIndex: 1 }}
											>
												{findItem.link && (
													<Typography
														variant="body2"
														fontFamily={BarlowFont.style.fontFamily}
														fontWeight={700}
														lineHeight={1.32}
														textTransform="inherit"
														color={theme.palette.primary.main}
													>
														{t('form:button.watchNow')}
													</Typography>
												)}

												{findItem.date && (
													<Typography
														variant="body2"
														fontFamily={BarlowFont.style.fontFamily}
														fontSize={12}
														fontWeight={400}
														lineHeight={1.32}
														color={theme.palette.grey[200]}
													>
														{dayjs(findItem.date).format('MMM DD, YYYY').toString()}
													</Typography>
												)}
											</CardActions>
										</Card>
									</Link>
								</Grid>
							)
						})}
					</Grid>

					<Grid container mt={2} justifyContent="end">
						<Link href="https://youtube.com/@delinegotinegovernment5894/videos">
							<Button endIcon={<ArrowRightAltIcon />} sx={{ color: '#ed1651' }}>
								{t('form:button.allVideos')}
							</Button>
						</Link>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default VideosMolecule
