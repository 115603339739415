'use client'

import { useState, useCallback, useEffect } from 'react'
import Image from 'next/image'
import { useTheme, alpha } from '@mui/material/styles'
import { ImageList, ImageListItem, ImageListItemBar, Grid, IconButton } from '@mui/material'
import { blue } from '@mui/material/colors'
import { Circle as CircleIcon } from '@mui/icons-material'

// Types
import type { SlideshowProps } from '@/types/components/molecules/slideshow'

// Fonts
import RobotoFont from '@/styles/fonts/roboto'

const SlideshowMolecule = (props: SlideshowProps) => {
	// Props
	const { items } = props

	// Variables
	const theme = useTheme()
	const [show, setShow] = useState<number>(0)
	const [forceShow, setForceShow] = useState<number>(0)

	const NextSlide = useCallback(() => {
		setShow(forceShow != -1 ? forceShow : (show + 1) % items.length)
		setForceShow(-1)
	}, [forceShow, items, show])

	useEffect(() => {
		if (forceShow != -1) {
			NextSlide()
			return
		}

		const interval = setInterval(NextSlide, 10000)
		return () => clearInterval(interval)
	}, [forceShow, NextSlide])

	return (
		items.length && (
			<Grid width="100vw" height={`${(items[show].height / items[show].width) * 100}vw`} position="relative" my={0} overflow="hidden">
				<ImageList cols={1} gap={0} rowHeight="auto">
					{items.map((item, index) => (
						<ImageListItem
							key={index}
							sx={{
								visibility: show == index ? 'visible' : 'hidden',
								opacity: show == index ? 1 : 0,
								width: '100%',
								position: 'absolute',
								top: '50%',
								left: 0,
								transform: 'translateY(-50%)',
								transition: 'all .3s ease',
								zIndex: 1,
								'&::after': {
									content: '""',
									width: '100%',
									height: '100%',
									position: 'absolute',
									top: 0,
									left: 0,
									backgroundColor: alpha(theme.palette.common.black, 0.4),
									zIndex: 1
								},
								'& img': {
									width: '100%',
									maxWidth: '100%',
									height: 'auto',
									verticalAlign: 'middle',
									border: 0
								}
							}}
						>
							<picture>
								<img src={`${item.image}`} width={item.width} height={item.height} alt={item.title ?? ''} />
							</picture>

							<ImageListItemBar
								title={item.title}
								subtitle={item.subtitle}
								sx={{
									top: '50%',
									p: '5vw',
									backgroundColor: 'transparent',
									transform: 'translateY(-50%)',
									zIndex: 2,
									'& .MuiImageListItemBar-title': {
										fontSize: { xs: '1.5em', sm: '3m', md: '4m', lg: '5em', xl: '6m' },
										lineHeight: '1em',
										fontWeight: 'bold'
									},
									'& .MuiImageListItemBar-subtitle': {
										width: { xs: '100%', md: '45%' },
										mt: { xs: 2, md: 6 },
										fontFamily: RobotoFont.style.fontFamily,
										fontSize: { xs: '0.75em', sm: '0.8m', md: '.95m', lg: '1.25em' },
										fontWeight: 'medium',
										lineHeight: '1em',
										whiteSpace: 'normal',
										color: '#c8d7ef'
									}
								}}
							/>
						</ImageListItem>
					))}
				</ImageList>

				<Grid
					container
					direction="row"
					justifyContent="center"
					alignItems="center"
					spacing={1}
					width="auto"
					position="absolute"
					left="50%"
					bottom={{ xs: '0.5rem', md: '2rem' }}
					zIndex={10}
					sx={{ transform: 'translateX(-50%)' }}
				>
					{items.map((item, index) => (
						<Grid key={index} item>
							<IconButton
								size="small"
								color={show == index ? 'primary' : 'default'}
								aria-label={item.title ?? ''}
								onClick={() => {
									setForceShow(index)
								}}
								sx={{
									color: show == index ? blue[400] : blue[600],
									transition: 'all .3s ease',
									p: 0.5,
									'& .MuiSvgIcon-root': {
										fontSize: '1em'
									},
									'&::before': {
										content: '""',
										opacity: show == index ? 1 : 0,
										width: '100%',
										height: '100%',
										position: 'absolute',
										top: 0,
										left: 0,
										border: `2px solid ${blue[600]}`,
										borderRadius: '50%',
										transition: 'all .3s ease',
										zIndex: -1
									}
								}}
							>
								<CircleIcon />
							</IconButton>
						</Grid>
					))}
				</Grid>
			</Grid>
		)
	)
}

export default SlideshowMolecule
