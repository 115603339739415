'use client'

import { useCallback, useEffect, useState } from 'react'
import Link from 'next/link'
import dayjs from 'dayjs'
import { useTheme, alpha } from '@mui/material/styles'
import { Grid, Container, Typography, IconButton, CardMedia, CardContent, Card, CardActions, Button } from '@mui/material'
import { ArrowBackIosNew as ArrowBackIosNewIcon, ArrowForwardIos as ArrowForwardIosIcon, ArrowRightAlt as ArrowRightAltIcon } from '@mui/icons-material'

// Types
import type { NewsProps } from '@/types/components/molecules/news'
import type { APIArticleItem } from '@/types/api/items/article'
import type { APIArticleReadData } from '@/types/api/article/read'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Fonts
import RobotoFont from '@/styles/fonts/roboto'
import BarlowFont from '@/styles/fonts/barlow'

const NewsMolecule = (props: NewsProps) => {
	// Props
	const { id } = props

	// Variables
	const { t } = useTranslation()
	const theme = useTheme()
	const [show, setShow] = useState<number>(0)
	const [loading, setLoading] = useState<boolean>(true)
	const [items, setItems] = useState<APIArticleItem[]>([])

	// Callbacks
	const readItems = useCallback(async () => {
		setLoading(true)
		// Result
		const response = await fetch(`/api/article/read?search=2&type=category&limit=10&skip=0`)
		const result: APIArticleReadData = await response.json()
		setLoading(false)

		if (result.status && result.data && result.data.items) setItems(result.data.items)
	}, [])

	useEffect(() => {
		if (loading) readItems()
	}, [loading, readItems])

	return (
		<Grid id={id} overflow="hidden">
			<Container sx={{ py: { xs: 4, md: 16 }, position: 'relative' }}>
				<Grid position="relative" sx={{ zIndex: 2 }}>
					<Grid container justifyContent="space-between" alignItems="center" spacing={{ sm: 4 }} mb={{ md: 2 }}>
						<Grid item xs={12} md={6} mb={2}>
							<Grid container spacing={1}>
								{items.length ? (
									<Grid item xs={12}>
										<Typography variant="h6" component="h6" fontSize={{ xs: 14, md: 16 }} fontWeight={700} textTransform="uppercase" color="#014178">
											{dayjs(items[0].createdDate).format('MMM DD, YYYY').toString()}
										</Typography>
									</Grid>
								) : null}

								<Grid item xs={12}>
									<Typography
										variant="h3"
										component="h3"
										fontSize={{ xs: 24, md: 48 }}
										fontWeight={700}
										color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
									>
										{t('common:title.latestNews')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={6} mb={2}>
							<Grid container justifyContent="end" spacing={1}>
								<Grid item>
									<IconButton
										size="large"
										onClick={() => {
											setShow((show - 1 + items.length) % items.length)
										}}
										sx={{
											color: theme.palette.secondary.main,
											backgroundColor: alpha(theme.palette.secondary.main, 0.1),
											'&:hover': {
												color: '#f2f2f2',
												backgroundColor: alpha(theme.palette.secondary.main, 0.4)
											}
										}}
									>
										<ArrowBackIosNewIcon />
									</IconButton>
								</Grid>

								<Grid item>
									<IconButton
										size="large"
										onClick={() => {
											setShow((show + 1) % items.length)
										}}
										sx={{
											color: theme.palette.secondary.main,
											backgroundColor: alpha(theme.palette.secondary.main, 0.1),
											'&:hover': {
												color: '#f2f2f2',
												backgroundColor: alpha(theme.palette.secondary.main, 0.4)
											}
										}}
									>
										<ArrowForwardIosIcon />
									</IconButton>
								</Grid>
							</Grid>
						</Grid>
					</Grid>

					<Grid container flexWrap="nowrap" spacing={2} width="fit-content">
						{items.length
							? items.map((item, index) => {
									const findItem = items[(index + show) % items.length]

									return (
										<Grid key={index} item width={{ xs: 'calc(100vw - 1em)', sm: '50vw', md: '33.333333vw', lg: '25vw', xl: '20vw' }}>
											<Card
												sx={{
													mb: 2,
													borderRadius: 1,
													boxShadow: `0px 6px 20px 0px ${alpha(theme.palette.grey[900], 0.1)}`
												}}
											>
												{findItem.image && (
													<CardMedia sx={{ height: { xs: '50vh', md: '30vh' } }} image={`/api/media/download${findItem.image}`} title={findItem.title} />
												)}

												<CardContent sx={{ minHeight: '8em' }}>
													{findItem.title && (
														<Typography
															fontFamily={RobotoFont.style.fontFamily}
															fontSize={20}
															fontWeight={700}
															color={theme.palette.mode == 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark}
														>
															{findItem.title}
														</Typography>
													)}

													{/* {findItem.author && (
												<Typography
													variant="body2"
													fontFamily={BarlowFont.style.fontFamily}
													fontSize={14}
													fontWeight={400}
													color={theme.palette.mode == 'dark' ? theme.palette.secondary.dark : '#112b3c'}
												>
													{findItem.author}
												</Typography>
											)} */}
												</CardContent>

												<CardActions sx={{ justifyContent: 'space-between' }}>
													<Link href={`/news/${findItem.alias}`}>
														<Button
															sx={{
																fontFamily: BarlowFont.style.fontFamily,
																fontWeight: 700,
																lineHeight: 1.32,
																textTransform: 'inherit',
																color: '#005BAA'
															}}
														>
															{t('form:button.readMore')} ...
														</Button>
													</Link>

													{findItem.createdDate && (
														<Typography
															variant="body2"
															fontFamily={BarlowFont.style.fontFamily}
															fontSize={12}
															fontWeight={400}
															lineHeight={1.32}
															color={theme.palette.mode == 'dark' ? theme.palette.secondary.dark : '#808080'}
														>
															{dayjs(findItem.createdDate).format('MMM DD, YYYY').toString()}
														</Typography>
													)}
												</CardActions>
											</Card>
										</Grid>
									)
								})
							: null}
					</Grid>

					<Grid container mt={2} justifyContent="end">
						<Link href="/news">
							<Button endIcon={<ArrowRightAltIcon />} sx={{ color: '#ed1651' }}>
								{t('form:button.archive')}
							</Button>
						</Link>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default NewsMolecule
